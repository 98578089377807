import './App.css'
// import NavBar from "./NavBar";
import ArtisticProjects from './ArtisticProjects'
import SoftwareList from './SoftwareList'
import styles from "./App.css"
function App() {
  return (
    <div>
      {/* <NavBar /> */}
      {/* <!-- Header --> */}
      <section className='hero has-text-light has-background-dark is-link is-fullheight is-fullheight-with-navbar'>
        <img className='heroJedi' src='/holomorfoBWsq.jpg' alt='' srcSet='' />
        <div className='hero-body'>
          <div className='container'>
            <h1 className='title is-1 has-text-light animate__animated animate__fadeIn animate__slower'>
              Cristian Bañuelos
            </h1>
            <h2 className='subtitle is-3 has-text-light typing-demo'>
              dr_holomorfo
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- About --> */}
      <section className='section' id='about'>
        {/* <!-- Title --> */}
        <div className='container'>
          <div className='container has-text-justified is-half'>
            <div className='container'>
              <div class='columns'>
                <div class='column has-text-right is-one-third'>
                  <h3 className='title is-2'>dr_holomorfo</h3>
                  <h4 className='subtitle is-5'>Music technology</h4>
                </div>

                <div class='column is-half'>
                  <p>
                    Multifaceted artist and programmer, involved in virtual
                    reality installations, dance, music performances, video
                    games, interactive installations, albums, and algorithmic
                    compositions. With a strong programming background, I
                    integrate technology into my art, using virtual reality,
                    machine learning, Python, JavaScript, Unity, Processing, SuperCollider, and
                    genetic algorithms. Collaborating locally and
                    internationally, I've achieved recognition for projects like
                    the ARGAX Virtual reality music installation and
                    Intercambios quatre performance, showcasing my blend of
                    technology with art for creative expression. My involvement
                    in the Armstrong Liberado collective reflects my dedication
                    to artistic exploration and research. Through my diverse
                    portfolio, I aim to create immersive experiences that
                    resonate across various mediums and contexts.
                  </p>
                </div>
                {/* <div className='is-one-fifth'></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Portfolio --> */}
      <section className='section'>
        <div className='container'>
          <div class='columns has-text-centered is-on-third'>
            <div class='column'>
              <div class='card'>
                <div class='card-content'>
                  <div class='content'>
                    <h3 className='title is-2'>Software</h3>
                    <ul className='has-text-left'>
                      <li>Tonal Universe</li>
                      <li>SuperHarmony</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='column'>
              <div class='card'>
                <div class='card-content'>
                  <div class='content'>
                    <h3 className='title is-2'>Music</h3>
                    <ul className='has-text-left'>
                      <li>Audiobio</li>
                      <li>Generative music</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div class='column'>
              <div class='card'>
                <div class='card-content'>
                  <div class='content'>
                    <h3 className='title is-2'>Publications</h3>
                    <ul className='has-text-left'>
                      <li>DTW for music analysis</li>
                      <li>AI and ML for music analysis</li>
                      <li>Geometries associated to tonal music</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <ArtisticProjects />
          <br />
          <SoftwareList />
        </div>
      </section>
      {/* <!-- Footer --> */}
      <footer className='footer'>
        <div className='section-heading'>
          <p>dr_holomorfo 2022</p>
        </div>
      </footer>
    </div>
  )
}

export default App
