import React from "react";
import ProjectEntry from "../ProjectEntry";

export default function ArtisticProjects() {
  return (
    <div id="portfolio">
      <div className="section-heading">
        <h3 className="title is-2 has-text-centered">Artistic projects</h3>
      </div>
      <br />
      <ProjectEntry name="ARGAX" date={"Dec 2018"}>
        <img src="/Argax.jpeg" alt="project" />
        ARGAX Virtual reality music installation, México City, MX. Virtual
        reality music exploration system in which a user can interact in 3D with
        visualizations created by a music composer, Unity (C#). Project winner
        of the PAPIAM 2018 prize of the México Secretary of Culture.
        Responsibilities: Design, develop and optimize the virtual reality
        system in order that the user can explore the virtual worlds in a
        seamless way.
      </ProjectEntry>
      <ProjectEntry
        name="Intercambios quatre"
        date={"Aug 2016"}
        videoLink="https://player.vimeo.com/video/271711136"
      >
        Intercambios quatre, dance and music performance, Paris, France,
        Sorbonne Université, Multidisciplinary composition created in
        collaboration with students from Sorbonne Université for the MUXIC
        festival. Machine learning was used to coordinate soound effects
        triggered by dancer movements.
      </ProjectEntry>
      <ProjectEntry
        name="MAZIC"
        date="Sept 2015"
        videoLink="https://www.youtube.com/embed/sKwSSsp4wgA"
      >
        MAZIC, musical video game, MAZIC, México City, MX. Programmer of video
        game in Unity (C#) for the LEIVA festival, which uses musical elements
        as primary form of interaction.
      </ProjectEntry>
      <ProjectEntry
        name="Armstrong Liberado"
        date="May 2016"
        imageA="https://ia800307.us.archive.org/9/items/Dr.Levin/El%20laboratorio%20del%20Dr.%20Levin.jpg?cnt=0"
      >
        Armstrong Liberado collective, México, CDMX, Artistic collective
        dedicated to the creation, education and research of free music.
        Programmer and performer. The collective has presented on several
        festivals like: Hackmitin 2014, 2o. Encuentro de Arte Sonoro Random,
        UNAM, Hackerspace Rancho Electrónico, Spanish Cultural Center y
        Tlatelolco Cultural Center.. Aug 2015 Debajo, live performance, México,
        CDMX, Software developer for responsive interactive movement showscase
        system (Processing + SuperCollider).
      </ProjectEntry>
      <ProjectEntry name="Subsonic" date="Aug 2015">
        Subsonic, haptic storytelling system, México, CDMX, Software developer
        for luminous audio transducer from image to subsonic waves. Collaborator
        with Roberto Cabezas for the 3rd installment of the Ïnteracciones,
        Cuerpos en transito"(Developed in Unity 3D).
      </ProjectEntry>
      <ProjectEntry name="Audiobio" imageA="/holomorfoSq.jpg" date="Oct 2015">
        Audiobio, algoritmic compositions, México, CDMX, Complied of algorithmic
        pieces composed between 2013 and 2015. July 2015 Disco: Three Kids
        Music, México, CDMX, Album GURRISONIC ORCHESTRA, Collabo- rator in the
        visual art for the album using data-bending and glitch art.
      </ProjectEntry>
      <ProjectEntry
        name="Level 3 Error"
        date="May 2015"
        imageA="https://ia800305.us.archive.org/28/items/3RR0RC4P48/portada-wordpress.jpg?cnt=0"
      >
        ALBUM: 3RR0R C4P4 8 (Level 8 error), México, CDMX, Album composed as
        part of the Armstrong Collective.
      </ProjectEntry>
      <ProjectEntry
        name="Destruction of memory"
        date="Jan 2015"
        videoLink="https://player.vimeo.com/video/105943748"
      >
        México, CDMX, Movable speakers ensemble and data-bending presented in
        the University Museum of Contemporary Art - UNAM, in collaboration with
        Neuralxólotl, Fabián Ávila. Aug 2014 Punto y coma, interactive
        installation, México, CDMX, PAPIAM - Multimedia Center, CNA., Software
        developer for interactive acoustical system in SuperCollider for one of
        the winning projects of the PAPIAM festival in charge of the artist Elsa
        Oviedo.
      </ProjectEntry>
      <ProjectEntry
        name="Digital transgression"
        date="June 2014"
        videoLink="https://player.vimeo.com/video/104455960"
      >
        Digital transgression, México, CDMX, Interactive composition for
        electric harp, performer Laura Vargas, presented in the University
        Museum of Contemporary Art, UNAM.
      </ProjectEntry>
      <ProjectEntry name="Genetic studies" date="June 2014">
        Genetic algorithms studies, México, CDMX, Algorithmic composition with
        genetic algorithms presented at LIMME-UNAM.
      </ProjectEntry>
      <ProjectEntry name="Short stories" date="Feb 2014">
        Short stories for Clavinova, México, CDMX, UNAM, Algorithmic composition
        created from E.A. Poe. The black cat, and Around the world in 80 days.
      </ProjectEntry>
      <ProjectEntry
        name="Auditive coreography"
        date="Feb 2012"
        videoLink="https://player.vimeo.com/video/102103375"
      >
        Tijuana, B.C., Colaborador FECAS - Jóvenes Creadores., Inter- active
        composition system where music is created in triggered with the
        movements of the dancer via accelerometer sensors. Dancer and
        choreographer: Viviana Martinez.
      </ProjectEntry>
      <ProjectEntry name="Harmonic reconciliation" date="April 2012">
        Tijuana, B.C., Winner of the PECDA creative youth scholarship by the
        Cultural Institute of Baja California, micro-tonal preludes for piano
        and synthesizer.
      </ProjectEntry>
      <ProjectEntry
        name="Antologia"
        videoLink="https://player.vimeo.com/video/118837084?title=0&byline=0&portrait=0"
      />
      <ProjectEntry name="Encuentros" />
      <ProjectEntry
        name="Encuentros"
        videoLink="https://player.vimeo.com/video/102183411"
      />
      <ProjectEntry
        name="Regression"
        videoLink="https://player.vimeo.com/video/102142728"
      />
    </div>
  );
}
