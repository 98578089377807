import React from "react";
import ProjectEntry from "../ProjectEntry";

export default function SoftwareList() {
  return (
    <div id="software">
      <div className="section-heading">
        <h3 className="title is-2 has-text-centered">
          Software and video games development
        </h3>
      </div>
      <br />

      <ProjectEntry name="Superharmony" date="November 2022">
        <a href="https://github.com/holomorfo/superharmony" target="_blank">
          Superharmony{" "}
        </a>
        is a SuperCollider library of utilities for exploring tonality. It
        containes classes for defining a Harmony, Tonality, TonalUniverse as a
        set of notes and functions between them.
      </ProjectEntry>
      <ProjectEntry name="Tonal universe" date="June 2022">
        June 2022 Typescript music library, San Francisco, CA. Music theory
        library in Typescript
      </ProjectEntry>
      <ProjectEntry name="Mirlo" date="June 2022">
        MIR library Python, San Francisco, CA. Music21 bridge library June 2020
        MIRLO, San Francisco, CA. Music analysis tool.
      </ProjectEntry>
      <ProjectEntry name="PAPIME" date="Nov 2022">
        PAPIME Software developer, México, CDMX, FAM -UNAM, Temperamentum,
        EvoCanto, Mirlo, Tonal analysis.
      </ProjectEntry>
      <ProjectEntry name="MOVE" date="Jun 2018">
        MOVE Meditation audio system, Mexico City, MX, remote collaboration.
        Interactive multi-channel audio system for non-medical therapeutic
        meditation and relaxation (JavaScript, Unity C#). Responsibilities:
        Creation of an Android App that connects to a multi channel audio system
        server for user interaction. In this project I was also responsible of
        choosing the best technology stack for the project according to the
        resources available, and the implementation of the sound processing
        server.
      </ProjectEntry>
      <ProjectEntry name="Caldera studio" date="July 2016">
        Video game programmer, Caldera Studio, México City, MX. Educational
        applications system. Responsibilities: Updating and optimizing the
        system for a series of educational applications developed in Unity (C#).
        Responsible for porting Android version to the Windows Phone system.
      </ProjectEntry>
      <ProjectEntry name="MAZIC" date="Sept 2015">
        MAZIC, México, CDMX, Centro Multimedia - CNA, Programador como parte del
        equipo de desarrollo del audiojuego MAZIC, bajo La dirección de Esteban
        Ruiz Velazco en el Laboratorio Interdisciplinar de Experimentación e
        Innovación con Videojuegos y Arte Interactivo. .
      </ProjectEntry>
      <ProjectEntry name="Stereo pacman" date="May 2015">
        Stereo Pacman, México, CDMX, Facultad de Música - UNAM, Multipleayer
        videogame to be played in a theather with a projection contolled by
        mobile phones, colaboration with Esteban Ruiz-Velazco.
      </ProjectEntry>
    </div>
  );
}
