import React from "react";

export default function ProjectEntry(props) {
  const { name, videoLink, description, imageA, imageB, date } = props;
  return (
    <div>
      <div className="columns">
        <div className="column"></div>
        {/* <div className="column is half is-size-4">{date}</div> */}
        <div className="column is half is-size-4">{name}</div>
        <div className=" box column is-half">
          <div className="">
            {videoLink && (
              <figure className="image is-16by9">
                <iframe
                  src={videoLink}
                  title="video"
                  className="has-ratio"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </figure>
            )}
            {imageA && (
              <figure className="image is-square">
                <img src={imageA} alt="" srcSet="" />
              </figure>
            )}
            {imageB && (
              <figure className="image is-square">
                <img src={imageB} alt="" srcSet="" />
              </figure>
            )}
            {/* <p>{description}</p> */}
            <p>{props.children}</p>
          </div>
        </div>
        <div className="column"></div>
      </div>
    </div>
  );
}
